import PropTypes from "prop-types";
import React from "react";
import "./elements.css";

export const Navbar = () => {
  return (
    <div className={`navbar navbar-instance`}>
      <div className="frame">
        <a href="/" style={{display:"flex"}} >
          <img className="logo" alt="Logo" src='/logo.svg' />
          {/* <a href="#" class="close">
          &times;
          </a> */}
        </a>
        <div className="frame-2">
          {/* <a
            href="/"
            className={`nav-item ${window.location.pathname === "/" ? "selected-tab" : "inactive-text-tab-wrapper"}`}>
            Home
          </a> */}
          <a
            href="/"
            className={`nav-item ${window.location.pathname === "/" ? "selected-tab" : "inactive-text-tab-wrapper"}`}>
            For Merchants
          </a>
          <a
            href="/subscribers"
            className={`nav-item ${window.location.pathname === "/subscribers" ? "selected-tab" : "inactive-text-tab-wrapper"}`}>
            For Subscribers
          </a>
          {/* {window.location.pathname !== "/" && */}
          {/* <a
            href="https://documentation.jybe.ca/"
            className={`nav-item ${window.location.pathname === "/userinfo" ? "selected-tab" : "inactive-text-tab-wrapper"}`}>
              Start Jybing
            </a> */}
            {/* } */}
          {/* <a onClick={() => window.open("mailto:michaeltiller@jybe.ca")} className={`nav-item ${window.location.pathname === "/contact" ? "selected-tab" : "inactive-text-tab-wrapper"}`}>
            Contact
          </a> */}
        </div>
        {/* <Button
          className="design-component-instance-node"
          icon="false"
          size="lg"
          state="default"
          text="Contact Us"
          type="primary"
          onClick={() => window.open("mailto:michaeltiller@jybe.ca")}
        /> */}
        <button className="button-3" onClick={() => window.open("mailto:michaeltiller@jybe.ca")} >
          <div className="text-7">Contact Us</div>
        </button>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  logo: PropTypes.string,
};