/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const CursorBox1 = ({ className }) => {
  return (
    <svg
      className={`cursor-box-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M35 15.8333V13C35 10.1997 35 8.79961 34.455 7.73005C33.9757 6.78924 33.2108 6.02433 32.27 5.54497C31.2004 5 29.8003 5 27 5H13C10.1997 5 8.79961 5 7.73005 5.54497C6.78924 6.02433 6.02433 6.78924 5.54497 7.73005C5 8.79961 5 10.1997 5 13V27C5 29.8003 5 31.2004 5.54497 32.27C6.02433 33.2108 6.78924 33.9757 7.73005 34.455C8.79961 35 10.1997 35 13 35H15.8333M28.977 29.5189L26.1465 34.7755C25.6838 35.6347 25.4525 36.0643 25.1706 36.1764C24.9258 36.2738 24.6494 36.2496 24.4252 36.1113C24.1669 35.9521 24.0135 35.4888 23.7068 34.5624L19.1673 20.8522C18.8987 20.0409 18.7644 19.6353 18.861 19.3652C18.945 19.1301 19.1301 18.945 19.3651 18.861C19.6353 18.7644 20.0409 18.8987 20.8522 19.1673L34.5624 23.7068C35.4888 24.0136 35.952 24.167 36.1113 24.4252C36.2496 24.6494 36.2737 24.9258 36.1764 25.1706C36.0643 25.4526 35.6346 25.6839 34.7754 26.1466L29.5189 28.977C29.3879 29.0475 29.3225 29.0828 29.2652 29.1281C29.2143 29.1683 29.1683 29.2143 29.1281 29.2652C29.0828 29.3225 29.0475 29.3879 28.977 29.5189Z"
        stroke="url(#paint0_linear_1035_6802)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1035_6802"
          x1="36.2354"
          x2="8.06539"
          y1="11.4975"
          y2="30.1566"
        >
          <stop className="stop" stopColor="#1A4AB1" />
          <stop className="stop" offset="0.5" stopColor="#FF4173" />
          <stop className="stop" offset="1" stopColor="#FFB44E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
