import { Route, Routes } from "react-router-dom";
import { JybeBusinessPage } from "./JybeBusinessPage";
import { Subscribers } from "./Subscribers";
import { useAuth0 } from "@auth0/auth0-react";
//import Loading from "./Loading.js"
import { ProtectedRoute } from '../../ProtectedRoute';
import Admin from './Admin.js';
import MyAccount from './MyAccount';
import OrderSuccessPage from './Notflix.js'

function App() {
    // const { isLoading } = useAuth0();

    // if (isLoading) {
    //   return <Loading />;
    // }
    return (
        <Routes>
            <Route path="/myaccount" element={
                <ProtectedRoute component={MyAccount} />
            } />
            <Route path="/admin" element={
                <ProtectedRoute component={Admin} />
            } />
            <Route path="/subscribers" element={<Subscribers />} />
            <Route path="/order_success" element={<OrderSuccessPage/> }/>
            <Route path="/" element={<JybeBusinessPage />} />
        </Routes>
    )
}

export default App;