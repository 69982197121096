/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const OutlineUsersUsersGroupTwoRounded1 = ({ className }) => {
  return (
    <svg
      className={`outline-users-users-group-two-rounded-1 ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 70 70"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M34.9994 3.64844C27.3479 3.64844 21.1452 9.85116 21.1452 17.5026C21.1452 25.154 27.3479 31.3568 34.9994 31.3568C42.6508 31.3568 48.8535 25.154 48.8535 17.5026C48.8535 9.85116 42.6508 3.64844 34.9994 3.64844ZM25.5202 17.5026C25.5202 12.2674 29.7642 8.02344 34.9994 8.02344C40.2346 8.02344 44.4785 12.2674 44.4785 17.5026C44.4785 22.7378 40.2346 26.9818 34.9994 26.9818C29.7642 26.9818 25.5202 22.7378 25.5202 17.5026Z"
        fill="url(#paint0_linear_1038_12099)"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M52.4994 9.48177C51.2912 9.48177 50.3119 10.4611 50.3119 11.6693C50.3119 12.8774 51.2912 13.8568 52.4994 13.8568C56.5141 13.8568 59.0619 16.4985 59.0619 18.9609C59.0619 21.4234 56.5141 24.0651 52.4994 24.0651C51.2912 24.0651 50.3119 25.0445 50.3119 26.2526C50.3119 27.4607 51.2912 28.4401 52.4994 28.4401C58.1496 28.4401 63.4369 24.5526 63.4369 18.9609C63.4369 13.3692 58.1496 9.48177 52.4994 9.48177Z"
        fill="url(#paint1_linear_1038_12099)"
      />
      <path
        className="path"
        d="M19.6869 11.6693C19.6869 10.4611 18.7075 9.48177 17.4994 9.48177C11.8491 9.48177 6.56185 13.3692 6.56185 18.9609C6.56185 24.5526 11.8491 28.4401 17.4994 28.4401C18.7075 28.4401 19.6869 27.4607 19.6869 26.2526C19.6869 25.0445 18.7075 24.0651 17.4994 24.0651C13.4846 24.0651 10.9368 21.4234 10.9368 18.9609C10.9368 16.4985 13.4846 13.8568 17.4994 13.8568C18.7075 13.8568 19.6869 12.8774 19.6869 11.6693Z"
        fill="url(#paint2_linear_1038_12099)"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M34.9994 35.7318C29.7952 35.7318 24.9848 37.1341 21.4116 39.5163C17.8536 41.8882 15.3119 45.4053 15.3119 49.5859C15.3119 53.7666 17.8536 57.2836 21.4116 59.6556C24.9848 62.0378 29.7952 63.4401 34.9994 63.4401C40.2035 63.4401 45.0139 62.0378 48.5871 59.6556C52.1451 57.2836 54.6869 53.7666 54.6869 49.5859C54.6869 45.4053 52.1451 41.8882 48.5871 39.5163C45.0139 37.1341 40.2035 35.7318 34.9994 35.7318ZM19.6869 49.5859C19.6869 47.3232 21.0626 45.007 23.8384 43.1565C26.5989 41.3161 30.5385 40.1068 34.9994 40.1068C39.4602 40.1068 43.3998 41.3161 46.1603 43.1565C48.9361 45.007 50.3119 47.3232 50.3119 49.5859C50.3119 51.8486 48.9361 54.1649 46.1603 56.0154C43.3998 57.8557 39.4602 59.0651 34.9994 59.0651C30.5385 59.0651 26.5989 57.8557 23.8384 56.0154C21.0626 54.1649 19.6869 51.8486 19.6869 49.5859Z"
        fill="url(#paint3_linear_1038_12099)"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M56.196 40.3674C56.4547 39.1873 57.6212 38.4404 58.8013 38.6992C61.6068 39.3144 64.1348 40.4257 66.0117 41.9615C67.887 43.496 69.2702 45.6054 69.2702 48.1276C69.2702 50.6498 67.887 52.7592 66.0117 54.2937C64.1348 55.8295 61.6068 56.9408 58.8013 57.556C57.6212 57.8148 56.4547 57.0679 56.196 55.8878C55.9372 54.7078 56.684 53.5413 57.8641 53.2825C60.1752 52.7757 62.0221 51.9052 63.2411 50.9078C64.4617 49.909 64.8952 48.9335 64.8952 48.1276C64.8952 47.3217 64.4617 46.3462 63.2411 45.3474C62.0221 44.35 60.1752 43.4795 57.8641 42.9727C56.684 42.7139 55.9372 41.5474 56.196 40.3674Z"
        fill="url(#paint4_linear_1038_12099)"
      />
      <path
        className="path"
        d="M11.1974 38.6992C12.3775 38.4404 13.544 39.1873 13.8027 40.3674C14.0615 41.5474 13.3147 42.7139 12.1346 42.9727C9.82353 43.4795 7.97658 44.35 6.75762 45.3474C5.53701 46.3462 5.10352 47.3217 5.10352 48.1276C5.10352 48.9335 5.53701 49.909 6.75762 50.9078C7.97658 51.9052 9.82353 52.7757 12.1346 53.2825C13.3147 53.5413 14.0615 54.7078 13.8027 55.8878C13.544 57.0679 12.3775 57.8148 11.1974 57.556C8.39195 56.9408 5.8639 55.8295 3.987 54.2937C2.11174 52.7592 0.728516 50.6498 0.728516 48.1276C0.728516 45.6054 2.11174 43.496 3.987 41.9615C5.8639 40.4257 8.39195 39.3144 11.1974 38.6992Z"
        fill="url(#paint5_linear_1038_12099)"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1038_12099"
          x1="34.9994"
          x2="34.9994"
          y1="3.64844"
          y2="63.4401"
        >
          <stop className="stop" stopColor="#2A29EA" />
          <stop className="stop" offset="0.45" stopColor="#EFC327" />
          <stop className="stop" offset="1" stopColor="#FF4173" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_1038_12099"
          x1="34.9994"
          x2="34.9994"
          y1="3.64844"
          y2="63.4401"
        >
          <stop className="stop" stopColor="#2A29EA" />
          <stop className="stop" offset="0.45" stopColor="#EFC327" />
          <stop className="stop" offset="1" stopColor="#FF4173" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_1038_12099"
          x1="34.9994"
          x2="34.9994"
          y1="3.64844"
          y2="63.4401"
        >
          <stop className="stop" stopColor="#2A29EA" />
          <stop className="stop" offset="0.45" stopColor="#EFC327" />
          <stop className="stop" offset="1" stopColor="#FF4173" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_1038_12099"
          x1="34.9994"
          x2="34.9994"
          y1="3.64844"
          y2="63.4401"
        >
          <stop className="stop" stopColor="#2A29EA" />
          <stop className="stop" offset="0.45" stopColor="#EFC327" />
          <stop className="stop" offset="1" stopColor="#FF4173" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_1038_12099"
          x1="34.9994"
          x2="34.9994"
          y1="3.64844"
          y2="63.4401"
        >
          <stop className="stop" stopColor="#2A29EA" />
          <stop className="stop" offset="0.45" stopColor="#EFC327" />
          <stop className="stop" offset="1" stopColor="#FF4173" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint5_linear_1038_12099"
          x1="34.9994"
          x2="34.9994"
          y1="3.64844"
          y2="63.4401"
        >
          <stop className="stop" stopColor="#2A29EA" />
          <stop className="stop" offset="0.45" stopColor="#EFC327" />
          <stop className="stop" offset="1" stopColor="#FF4173" />
        </linearGradient>
      </defs>
    </svg>
  );
};
