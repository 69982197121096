/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowCircleBrokenDownRight1 = ({ className }) => {
  return (
    <svg
      className={`arrow-circle-broken-down-right-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.6847 3.90157C21.245 2.4167 27.4236 3.85534 31.7858 8.21749C38.2945 14.7262 38.2945 25.279 31.7858 31.7877C25.277 38.2965 14.7243 38.2965 8.21554 31.7877C3.85338 27.4256 2.41474 21.247 3.89962 15.6867M25.0009 15.0026V25.0026M25.0009 25.0026H15.0009M25.0009 25.0026L8.3339 8.33586"
        stroke="url(#paint0_linear_1035_6811)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1035_6811"
          x1="36.6673"
          x2="6.6053"
          y1="10.2698"
          y2="30.1822"
        >
          <stop className="stop" stopColor="#1A4AB1" />
          <stop className="stop" offset="0.5" stopColor="#FF4173" />
          <stop className="stop" offset="1" stopColor="#FFB44E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
