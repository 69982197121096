import React from "react";
import styled from "styled-components";
const TitleSection = ({closeModal}) => {

    return (
        <>
            <IconContainer>
                <Icon src={'https://testing.jybe.ca/logo.svg'} alt="Subscription Icon" />
                <a onClick={closeModal} style={{fontSize:'20px'}} >
                    &times;
                </a>
            </IconContainer>
            <Title>Subscribe Smarter with Jybe</Title>
        </>
    )
}

const IconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 2vh;
`

const Icon = styled.img`
  aspect-ratio: 4.17;
  object-fit: contain;
  width: 164px;
  align-self: center;
  max-width: 100%;
  margin-right: 30%;
`;

const Title = styled.h2`
  color: #28384c;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 162.5%;
  margin-top: 10px;
  font-size: 12px;
  @media (max-width: 991px) {
    white-space: initial;
    line-height: 120%;
    margin: 0px;
  }
`;


export default TitleSection