/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ShieldDollar1 = ({ className }) => {
  return (
    <svg
      className={`shield-dollar-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 41 40"
      width="41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.0203 24.1653C16.5406 25.1562 17.5796 25.832 18.7765 25.832H22.332C24.173 25.832 25.6654 24.3396 25.6654 22.4986C25.6654 20.6577 24.173 19.1653 22.332 19.1653H18.9987C17.1577 19.1653 15.6654 17.6729 15.6654 15.832C15.6654 13.991 17.1577 12.4986 18.9987 12.4986H22.5543C23.7511 12.4986 24.7901 13.1745 25.3104 14.1653M20.6654 9.99865V12.4986M20.6654 25.832V28.332M33.9987 19.9986C33.9987 28.1794 25.0754 34.1293 21.8287 36.0234C21.4597 36.2387 21.2752 36.3463 21.0148 36.4022C20.8128 36.4455 20.518 36.4455 20.3159 36.4022C20.0555 36.3463 19.871 36.2387 19.502 36.0234C16.2553 34.1293 7.33203 28.1794 7.33203 19.9986V12.028C7.33203 10.6955 7.33203 10.0292 7.54997 9.45649C7.74249 8.95055 8.05534 8.49911 8.46146 8.1412C8.92119 7.73604 9.54503 7.5021 10.7927 7.03423L19.729 3.6831C20.0755 3.55317 20.2488 3.4882 20.427 3.46245C20.5851 3.4396 20.7456 3.4396 20.9037 3.46245C21.082 3.4882 21.2552 3.55317 21.6017 3.6831L30.538 7.03423C31.7857 7.5021 32.4095 7.73604 32.8693 8.1412C33.2754 8.49911 33.5882 8.95055 33.7808 9.45649C33.9987 10.0292 33.9987 10.6955 33.9987 12.028V19.9986Z"
        stroke="url(#paint0_linear_1035_6815)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1035_6815"
          x1="33.9987"
          x2="7.10688"
          y1="10.3077"
          y2="24.7062"
        >
          <stop className="stop" stopColor="#1A4AB1" />
          <stop className="stop" offset="0.5" stopColor="#FF4173" />
          <stop className="stop" offset="1" stopColor="#FFB44E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
