import styled from "styled-components";

const GoBack = ({ onClick }) => {
    return (
        <Navigation onClick={onClick}>
            <div className="centersvg" >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M9.58084 4.04372C9.66862 4.13161 9.71792 4.25075 9.71792 4.37497C9.71792 4.49919 9.66862 4.61833 9.58084 4.70622L4.75662 9.53122L17.3746 9.53122C17.4989 9.53122 17.6181 9.58061 17.706 9.66852C17.794 9.75642 17.8433 9.87565 17.8433 9.99997C17.8433 10.1243 17.794 10.2435 17.706 10.3314C17.6181 10.4193 17.4989 10.4687 17.3746 10.4687L4.75662 10.4687L9.58084 15.2937C9.66364 15.3826 9.70871 15.5001 9.70657 15.6216C9.70443 15.743 9.65523 15.8589 9.56935 15.9447C9.48347 16.0306 9.3676 16.0798 9.24616 16.082C9.12473 16.0841 9.0072 16.039 8.91834 15.9562L3.29334 10.3312C3.20556 10.2433 3.15625 10.1242 3.15625 9.99997C3.15625 9.87575 3.20556 9.75661 3.29334 9.66872L8.91834 4.04372C9.00623 3.95594 9.12537 3.90663 9.24959 3.90663C9.37381 3.90663 9.49295 3.95594 9.58084 4.04372Z" fill="#222222" />
                </svg>
            </div>
            <BackText>Go back</BackText>
        </Navigation>
    )
}

export default GoBack

const Navigation = styled.nav`
  display: flex;
  margin-top: 20px;
  gap: 10px;
  font-size: 12px;
  color: #28384c;
  line-height: 24px;
  cursor: pointer;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const BackText = styled.span`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  font-size: 12px;
`;