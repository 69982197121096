import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../ProtectedRoute';
import "./MyAccount.css";
import { Aside } from "./components/Aside";
import { Header } from "./components/Header";
import { RightBody } from "./components/RightBody";
import { Subscription } from "./components/Subscription";
import ReactGA from 'react-ga4';
import { useNavigate } from "react-router-dom";
//import { Button } from "./Button"

function MyAccount() {
  const context = useContext(AccountContext);
  const { accountContext, user, accessToken, refreshToken, refreshTheClock } = context
  const [activeOrders, setActiveOrders] = useState()
  const [monthlySavings, setMonthlySavings] = useState()
  const [totalSavings, setTotalSavings] = useState()
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_API_URL == "https://api.jybe.ca") {
      ReactGA.event('page_view', {
        page_title: window.location.pathname + window.location.search,
        page_location: window.location.pathname + window.location.search,
      });
    }
  }, [])

  useEffect(() => {
    accountContext && accountContext.activeOrders && setActiveOrders(accountContext.activeOrders)
  }, [accountContext])

  function monthsPassedSince(timestamp) {
    // Convert the timestamp to a Date object
    const startDate = new Date(timestamp);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in months
    const monthsPassed = (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
      (currentDate.getMonth() - startDate.getMonth());
    return monthsPassed;
  }

  useEffect(() => {
    if (activeOrders != undefined && activeOrders.length > 0) {
      const monthly = activeOrders.reduce((acc, order) => {
        const monthly = (parseFloat(order.og_monthly_cost) - parseFloat(order.og_jybe_cost / 100)) + parseFloat(order.og_monthly_cost - parseFloat(order.og_jybe_cost / 100));
        return acc + monthly;
      }, 0)
      const totalSavings = activeOrders.reduce((acc, order) => {
        const savings = (order.og_monthly_cost - (order.og_jybe_cost / 100)) * monthsPassedSince(order.created_at);
        return acc + savings;
      }, 0);
      setMonthlySavings(monthly)
      setTotalSavings(totalSavings)
    }
  }, [activeOrders])

  return (
    <div class="container">
      {accountContext && <Header userInfo={accountContext} />}

      {activeOrders != undefined && activeOrders.length > 0 ? <div class="content-wrapper">
        {totalSavings != undefined && <Aside totalSavings={totalSavings} />}

        <div class="content">
          <div>
            <Subscription activeOrders={activeOrders} />
          </div>

          {monthlySavings != undefined && <RightBody {...{ monthlySavings, activeOrders }} />}
        </div>
      </div> :
        <div>
          <div className="section">
            <div className="form-wrapper">
              <div className="denied-form">
                <img
                  className="no-internet"
                  alt="No internet"
                  src="/sadelephant.png"
                />
                <p className="oh-no-sorry-you-do">
                  <span className="text-wrapper">You do not have any subscriptions to view! Click the link to apply for one</span>
                </p>
                {/* <p className="p">
                  Please confirm your personal information is correct or try again with a smaller order.
                </p> */}
                {/* <div className="button-wrapper">
                  <Button
                    className="button-instance"
                    icon="right"
                    size="lg"
                    state="default"
                    text="Place an order"
                    type="primary"
                    onClick={() => navigate("/userinfo")}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default MyAccount;