import React from 'react';
import "./style.css";
import { Footer } from "./Footer.js"
import { Navbar } from "./Navbar.js";
const OrderSuccessPage = () => {

    return (
        <div className="jybe-business-page" style={{
            background: 'rgb(15, 23, 73)'
            //background: 'linear-gradient(to bottom right, #fedb5933, #ff92654d 25%, #fe407466 50%, #9334ae80 75%, #2928ea99)'
        }} >
      <div className="section">
        {/* <div className="group-2">
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="group-3">
                <div className="group-4">
                  <div className="rectangle-2" />
                  <div className="rectangle-3" />
                </div>
                <div className="new-group-5">
                  <div className="rectangle-4" />
                  <div className="rectangle-5" />
                </div>
                <div className="group-6">
                  <div className="rectangle-6" />
                  <div className="rectangle-7" />
                </div>
                <div className="group-7">
                  <div className="rectangle-8" />
                  <div className="rectangle-9" />
                </div>
                <div className="group-8">
                  <div className="rectangle-10" />
                  <div className="rectangle-11" />
                </div>
                <div className="group-9">
                  <div className="rectangle-12" />
                  <div className="rectangle-13" />
                </div>
                <div className="group-10">
                  <div className="rectangle-14" />
                  <div className="rectangle-15" />
                </div>
              </div>
              <div className="text-wrapper-8">This Week</div>
              <div className="text-wrapper-9">Subscriptions</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-10">Overview</div>
              <div className="group-11">
                <div className="text-wrapper-11">Subscribers</div>
                <div className="text-wrapper-12">18,287</div>
              </div>
              <div className="group-12">
                <div className="text-wrapper-11">ROI</div>
                <div className="text-wrapper-13">+87%</div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="frame-5" style={{width:'75vw', textAlign:'center', background:'#rgba(255, 255, 255, 0.5)', padding:'32px', borderRadius:'16px', marginTop:'10vh' }} >
          <div className="columns">
            <h2 style={{
                textAlign:'center', 
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent', 
                background: 'linear-gradient(to bottom right, #fedb5933, #ff92654d 25%, #fe407466 50%, #9334ae80 75%, #2928ea99) text',
                fontSize:'5vw',
                lineHeight:'100px',
                fontWeight:500
            }} >
                This could be your order confirmation page
            </h2>
            <p className="white-paragraph" style={{
                marginTop: '20vh',   
                background: 'linear-gradient(to bottom left, #fedb5933, #ff92654d 25%, #fe407466 50%, #9334ae80 75%, #2928ea99) text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent' 
            }} >
              Get more subscribers, streamline cashflow and reduce churn by offering Buy Now, Pay Later to your subscribers with Jybe.
            </p>
          </div>
          {/* <button className="demo-button">
            <a href="https://calendly.com/michaeltiller" target="_blank" className="text-6">Book a Demo</a>
          </button> */}
        </div>
        {/* <img className="image" alt="Image" src="/ytppo.png" /> */}
      </div>
      <Footer />
      {/* <Navbar
        className="navbar-instance"
      /> */}
        </div>
    );
};

export default OrderSuccessPage;