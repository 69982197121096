/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const UserCheck011 = ({ className }) => {
  return (
    <svg
      className={`user-check-01-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 41 40"
      width="41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M20.3346 25.8333H12.8346C10.5087 25.8333 9.34573 25.8333 8.3994 26.1204C6.26873 26.7667 4.60137 28.4341 3.95503 30.5648C3.66797 31.5111 3.66797 32.6741 3.66797 35M27.0013 30L30.3346 33.3333L37.0013 26.6667M24.5013 12.5C24.5013 16.6421 21.1434 20 17.0013 20C12.8592 20 9.5013 16.6421 9.5013 12.5C9.5013 8.35786 12.8592 5 17.0013 5C21.1434 5 24.5013 8.35786 24.5013 12.5Z"
        stroke="url(#paint0_linear_1036_8785)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1036_8785"
          x1="37.0013"
          x2="8.9461"
          y1="11.2405"
          y2="31.8884"
        >
          <stop className="stop" stopColor="#1A4AB1" />
          <stop className="stop" offset="0.5" stopColor="#FF4173" />
          <stop className="stop" offset="1" stopColor="#FFB44E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
